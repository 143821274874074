import React, { ReactNode } from 'react'
import classNames from 'classnames'

import './Button.scss'

interface Props {
  to: string
  primary?: boolean
  children: ReactNode
  className?: any
  newTab?: boolean
  others?: any
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  forGlean?: boolean
}

const Button: React.SFC<Props> = ({
  children,
  to,
  primary,
  className,
  newTab,
  onClick,
  forGlean = false,
  ...others
}) => (
  <a
    href={to}
    target={newTab ? '_blank' : '_self'}
    className={classNames({
      [className]: !!className,
      button: true,
      'button--primary': primary,
      'button--glean': forGlean
    })}
    {...others}
    onClick={onClick}
  >
    {children}
  </a>
)

Button.defaultProps = {
  primary: false,
  newTab: false
}

export default Button
