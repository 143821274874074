import React from 'react'
import marked from 'marked'

import './Markdown.scss'
import classNames from 'classnames'

interface IProps {
  content: string
  className?: string
}

const encodeHTML = (html: string) => html.replace(/</g, '&lt;').replace(/>/g, '&gt;')

const toHTML = (content: string) => ({ __html: marked(encodeHTML(content)) })

const Markdown: React.SFC<IProps> = ({ content, className }) => (
  <div className={classNames(className + 'markdown-span')} dangerouslySetInnerHTML={toHTML(content)} />
)

Markdown.defaultProps = {
  className: ''
}

export default Markdown
