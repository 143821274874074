import React from 'react'
import classNames from 'classnames'

import Markdown from '../Markdown/Markdown'
import Heading, {HeadingLevel, HeadingStyle } from '../Heading/Heading'

import './TitledContent.scss'

interface Props {
  children: any
  title: string
  subtitle?: string
  center?: boolean
  bordered?: boolean
  condensedTitle?: boolean
  className?: any
  titleClassName?: string
  id?: any
  titleId?: any
}

const TitledContent: React.SFC<Props> = ({
  title,
  subtitle,
  children,
  center,
  bordered,
  condensedTitle,
  className,
  titleClassName,
  titleId,
  ...others
}) => (
  <div
    className={classNames({
      [className]: !!className,
      'titled-content': true,
      'titled-content--centered': center,
      'titled-content--bordered': bordered
    })}
    {...others}
  >
    <div
      className={classNames({
        'titled-content__title-container': true,
        'titled-content__title-container--condensed': condensedTitle
      })}
    >
      <Heading headingLevel={HeadingLevel.H2} headingStyle={HeadingStyle.TITLE} className={classNames("titled-content__title", titleClassName)} id={titleId}>
        <Markdown content={title} />
      </Heading>

      {subtitle && (
        <Heading
          headingLevel={HeadingLevel.H3}
          headingStyle={HeadingStyle.PARAGRAPH}
          className="titled-content__subtitle"
          id={titleId}
        >
          {subtitle}
        </Heading>
      )}
    </div>
    {children}
  </div>
)

TitledContent.defaultProps = {
  center: false,
  condensedTitle: false,
  bordered: false
}

export default TitledContent
