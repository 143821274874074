import React from 'react'

import './ContentContainer.scss'

interface Props {
  children: any
}

const ContentContainer: React.SFC<Props> =
  ({ children }) => <div className="content-container">{children}</div>

export default ContentContainer
