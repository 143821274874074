import React from 'react'
import classNames from 'classnames'

import Markdown from '../Markdown/Markdown'
import FlexBox, { Justification } from '../FlexBox/FlexBox'
import ContentContainer from '../ContentContainer/ContentContainer'
import Heading, { HeadingLevel, HeadingStyle } from '../Heading/Heading'

import './Jumbotron.scss'

interface Props {
  children: any
  image: string
  title: string
  alt?: string
  reverse?: boolean
}

const Jumbotron: React.SFC<Props> = ({ image, title, alt, reverse, children }) => (
  <header className="jumbotron">
    <ContentContainer>
      <FlexBox
        justify={Justification.CENTER}
        className={classNames('jumbotron__flexbox', {
          'jumbotron__flexbox--reverse': reverse
        })}
      >
        <div className="jumbotron__flexbox__content-container">
          <Heading
            headingLevel={HeadingLevel.H1}
            headingStyle={HeadingStyle.HEADLINE}
            className="jumbotron__flexbox__content-container__title"
            id="jumbotron-title"
          >
            <Markdown content={title} />
          </Heading>
          {children}
        </div>
        <FlexBox justify={Justification.CENTER} vertical>
          <div className="jumbotron__flexbox__image-container">
            <img src={image} alt={alt} />
          </div>
        </FlexBox>
      </FlexBox>
    </ContentContainer>
  </header>
)

Jumbotron.defaultProps = {
  alt: '',
  reverse: false
}

export default Jumbotron
