import React from 'react'
import classNames from 'classnames'

import './FullWidthSection.scss'

export enum BackgroundColor {
  WHITE,
  DARKGREY
}

export enum PaddingOption {
  NONE,
  TINY,
  SMALL,
  MEDIUM,
  LARGE
}

interface IProps {
  bgColor?: BackgroundColor,
  paddingTop: PaddingOption,
  paddingBottom: PaddingOption,
  className?: any,
  children: any
}

const FullWidthSection: React.SFC<IProps> =
  ({ bgColor, paddingTop, paddingBottom, className, children }) => <section className={classNames({
    [className]: !!className,
    'full-width-section': true,
    'full-width-section--bg-white': bgColor === BackgroundColor.WHITE,
    'full-width-section--bg-dark-grey': bgColor === BackgroundColor.DARKGREY,
    'full-width-section--pt-none':  paddingTop === PaddingOption.NONE,
    'full-width-section--pt-tiny': paddingTop === PaddingOption.TINY,
    'full-width-section--pt-small': paddingTop === PaddingOption.SMALL,
    'full-width-section--pt-medium': paddingTop === PaddingOption.MEDIUM,
    'full-width-section--pt-large': paddingTop === PaddingOption.LARGE,
    'full-width-section--pb-none':  paddingBottom === PaddingOption.NONE,
    'full-width-section--pb-tiny': paddingBottom === PaddingOption.TINY,
    'full-width-section--pb-small': paddingBottom === PaddingOption.SMALL,
    'full-width-section--pb-medium': paddingBottom === PaddingOption.MEDIUM,
    'full-width-section--pb-large': paddingBottom === PaddingOption.LARGE
  })}>{children}</section>

FullWidthSection.defaultProps = {
  bgColor: BackgroundColor.WHITE
}

export default FullWidthSection